/* eslint-disable vue/no-v-html */
<template>
  <div class="mb-20">
    <h1 class="text-xl text-color font-semibold page-title mb-5 mt-5">
      {{ $t('sidebar_menu_children.Call logs') }}
    </h1>
    <div class="flex -mx-3">
      <div
        class="px-3 w-full">
        <div class="w-full">
          <div class="relative">
            <div class="flex justify-between mb-10 items-center">
              <span class="text-sm text-grey block">
                {{ $t('video.call_logs.index[0]') }}
              </span>
              <div class="ml-auto">
                <!-- Search filter -->
                <div class="w-full">
                  <el-input
                    v-model="searchInput"
                    :placeholder="$t('video.call_logs.index[1]')"
                    suffix-icon="el-icon-search"
                    size="small"
                    class="w-64"
                    @keyup.native="updateSearch" />
                </div>
              </div>
            </div>
            <Table
              :loading="getLoading"
              :data="conferences"
              :search="searchInput"
              @show-detail="openConferenceDetailModal" />
            <div class="flex justify-end items-center mt-5">
              <!-- Pagination -->
              <span class="text-xs text-grey-dark mr-2">
                {{ $t('app_labels.page') }} {{ currPage + 1 }}
              </span>
              <el-button-group
                v-if="callLogTotal"
                class="relative">
                <el-button
                  v-if="currPage > 0"
                  :disabled="getLoading"
                  icon="el-icon-arrow-left"
                  size="mini"
                  @click="handlePagination({ cursor: cursors['prevCursor'], dir: -1 })">
                  {{ $t('actions.prev') }}
                </el-button>
                <el-button
                  v-if="cursors['nextCursor']"
                  :disabled="getLoading"
                  size="mini"
                  @click="handlePagination({ cursor: cursors['nextCursor'], dir: 1 })">
                  {{ $t('actions.next') }}
                  <i class="el-icon-arrow-right" />
                </el-button>
              </el-button-group>
            </div>
          </div>
        </div>
      </div>
      <div class="main__aside px-3">
        <div class="pt-20 -mt-20 sticky pin-t">
          <Filters
            :default-date-filter="dateFilterSelect"
            :date-options="shortcutDateFilter()"
            :agent="agentFilterSelect"
            :agent-list="agents"
            :status="statusFilterSelect"
            :status-list="statusOptions"
            :tenant="tenant"
            @update-filter="updateFilterQuery" />
          <div class="mt-5 shadow p-5 rounded flex flex-col justify-center">
            <p class="text-sm text-black text-center">
              {{ $t('video.call_logs.index[2]') }}
            </p>
            <el-button
              :loading="csvLoading"
              :disabled="!conferences.length"
              class="mt-2"
              size="small"
              type="primary"
              @click="exportCsv">
              {{ $t('actions.export') }}
            </el-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState, mapActions } from 'vuex';
import { ExportToCsv } from 'export-to-csv';
import _ from 'lodash'; // eslint-disable-line
// eslint-disable-next-line no-unused-vars
import moment from 'moment';

import phoneFormatter from '@/mixins/phoneFormatter';
import timeFormatter from '@/mixins/timeFormatter';
import statusList from '@/mixins/status';
import region from '@/mixins/region';
import dateFilter from '@/mixins/dateFilter';

import statusJson from '@/json/status';

import Table from './partials/Table.vue';
import Filters from './partials/Filters.vue';

export default {

  components: {
    Table,
    Filters,
  },

  mixins: [
    phoneFormatter,
    timeFormatter,
    statusList,
    region,
    dateFilter,
  ],

  props: {
    from: {
      type: String,
      required: false,
      default: '',
    },
    to: {
      type: String,
      required: false,
      default: '',
    },
    agent: {
      type: String,
      required: false,
      default: '',
    },
    q: {
      type: String,
      required: false,
      default: '',
    },
    status: {
      type: String,
      required: false,
      default: '',
    },
  },

  data() {
    return {
      csvLoading: false,
      dateFilterSelect: [],
      agentFilterSelect: 0,
      statusFilterSelect: 0,
      searchInput: '',
      statusOptions: statusJson,
      currPage: 0,
      cursor: '',
      dir: '',
      isExportModalVisible: false,
      userData: {},
    };
  },

  computed: {
    ...mapState({
      conferenceLogsAll: state => state.calls.conferenceLogsAll,
    }),
    ...mapGetters({
      conferences: 'getConferenceLogs',
      conferencesCsvList: 'getConferenceLogs',
      getLoading: 'getCallLogLoading',
      callLogTotal: 'getCallLogTotal',
      agents: 'getAgents',
      cursors: 'getCursorOption',
      tz: 'getTz',
      tenant: 'getTenant',
    }),
  },

  watch: {
    '$route': 'fetchData',
  },

  created() {
    try {
      const user = localStorage.getItem('CPV3_User') || {};

      this.userData = JSON.parse(user);
    } catch (e) {
      // do nothing
    }

    this.fetchData();
  },

  methods: {
    ...mapActions({
      getCsvData: 'fetchConferenceLogsCsv',
      fetchTenant: 'fetchTenant',
    }),

    // Export CSV file
    exportCsv() {
      const {
        from, to, agent, q, status, dir,
      } = this;
      const { from: defaultFrom, to: defaultTo } = this.defaultDate();

      // Assign input models with store filter
      this.searchInput = q || '';
      this.dateFilterSelect = [from || defaultFrom, to || defaultTo];
      this.agentFilterSelect = agent || '';
      this.statusFilterSelect = status || statusJson[0].value;

      const query = {
        from: from || defaultFrom,
        to: to || defaultTo,
        agent: this.agentFilterSelect,
        q: this.searchInput,
        status: this.statusFilterSelect,
        cursor: '',
        limit: 99999,
        dir: dir || 1,
        download: 1,
      };


      const promises = [
        this.getCsvData(query),
      ];

      this.csvLoading = true;

      Promise.all(promises)
        .then((data) => {
          const d = data[0].filter(x => x).map((user) => {
            const {
              Name,
              Login,
              Reference,
              Duration,
              CreatedAt: Start,
              Status,
              Origin,
              UserB_MSISDN: Phone,
              GuestMetaData,
              AgentRating,
              GuestRating,
              GuestFeedback,
              Location,
            } = user;

            const guestData = GuestMetaData && GuestMetaData.length && GuestMetaData[0];
            const dd = Start ? this.getLocalDateTimeTz(Start,
              this.tz.timeZoneOffset,
              this.tz.timeZone,
              'M/DD/YYYY hh:mm A') : '';

            return {
              'Agent': Name || '',
              'Email': Login || '',
              'Phone': Phone !== null && Phone !== undefined ? this.formatPhone(Phone) : '',
              'Origin': Origin || '',
              'Reference': Reference || '',
              'CallType': Origin || '',
              'Duration': this.FormatDuration(Duration) || 0,
              'DateTime': dd,
              'Status': this.getStatus(Status) || Status,
              'Agent Rating': AgentRating || '',
              'Guest Rating': GuestRating || '',
              'Guest Feedback': GuestFeedback || '',
              'Guest OS': (guestData && `${guestData.os} ${guestData.os_version}`) || '',
              'Guest Browser': (guestData && `${guestData.browser} ${guestData.browser_version}`) || '',
              'Guest Device': (guestData && guestData.device_name) || '',
              'Guest Bandwidth': guestData && !isNaN(parseInt(guestData.bandwidth_mbps, 10)) ? guestData.bandwidth_mbps : '', // eslint-disable-line
              'Guest Quality Rating': guestData && !isNaN(parseInt(guestData.quality_rating, 10)) ? guestData.quality_rating : '', // eslint-disable-line
              Location,
            };
          });

          const options = {
            fieldSeparator: ',',
            quoteStrings: '"',
            decimalSeparator: '.',
            showLabels: true,
            showTitle: true,
            title: 'Calls Report',
            useTextFile: false,
            useBom: true,
            useKeysAsHeaders: true,
          };
          const csvExporter = new ExportToCsv(options);
          csvExporter.generateCsv(d);
        })
        .then(() => {
          this.csvLoading = false;
          this.$notify({
            title: this.$t('video.call_logs.index[3]'),
            message: this.$t('success.exported', { value: this.$t('sidebar_menu_children.Call logs') }),
            type: 'success',
          });
        })
        .catch((err) => {
          this.csvLoading = false;
          this.$showError(this, err);
        });
    },

    // Open/Close conference detail modal
    openConferenceDetailModal(conference) {
      this.$store.dispatch('fetchConferenceDetails', conference)
        .catch((err) => {
          this.$showError(this, err);
        });
    },

    // Update query
    updateFilterQuery(query) {
      // Reset pagination back to page 1
      this.cursor = '';
      this.dir = 1;
      this.currPage = 0;

      this.$router.push({
        query: {
          ...this.$route.query,
          ...query,
        },
      });
    },

    // Update Search
    updateSearch: _.debounce(
      function update() {
        // only search if search character is eqal to or more than 3
        if (this.searchInput.length > 2 || this.searchInput.length <= 0) {
          this.updateFilterQuery({
            q: this.searchInput.trim(),
          });
        }
      }, 2000,
    ),

    handlePagination(payload) {
      const { dir, cursor } = payload;
      this.dir = dir;
      this.cursor = cursor;
      this.currPage += dir;

      this.fetchData();
    },

    // Fetch Data
    async fetchData() {
      const {
        from, to, agent, q, status,
      } = this;
      const { from: defaultFrom, to: defaultTo } = this.defaultDate();

      // Assign input models with store filter
      this.searchInput = q || '';
      this.dateFilterSelect = [from || defaultFrom, to || defaultTo];
      this.agentFilterSelect = agent || '';
      this.statusFilterSelect = status || statusJson[0].value;

      try {
        if (this.userData.AccountId) {
          await this.fetchTenant({
            uId: this.userData.AccountUid,
            accountId: this.userData.AccountId,
          });
        }
        // Fetch all calls
        await this.$store.dispatch('fetchConferenceLogs', {
          from: from || defaultFrom,
          to: to || defaultTo,
          agent: this.agentFilterSelect,
          q: this.searchInput,
          status: this.statusFilterSelect,
          cursor: parseInt(this.cursor, 10) || '',
          dir: this.dir || 1,
          timeZone: this.tz.timeZone,
          utcOffset: this.tz.timeZoneOffset,
        });

        // Fetch agent list option
        await this.$store.dispatch('fetchAgents', {
          fields: ['UserId', 'Name', 'Login'],
        });
      } catch (err) {
        this.$showError(this, err);
      }
    },
  },
};
</script>

<style>
  .el-table .error-row {
    background: #f1f5f8;
  }
</style>
